<template>
    <div class="action-refund-reject">
        <div class="title" v-html="title" />
        <div v-if="body" class="body" v-html="body" />
    </div>
</template>
<script>
export default {
    name: 'ActionRefundReject',
    props: ['message'],
    computed: {
        title() {
            return this.$translate('ACTION_REFUND_REJECT_TITLE')
        },
        body() {
            return this.$translate('ACTION_REFUND_REJECT_BODY')
        },
    },
}
</script>
